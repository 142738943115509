body {
  min-height: 100vh;
}

label {
  margin-bottom: 0px;
}

legend {
  font-size: 1.2rem;
}
