@use 'sass:map';
// General
$font-family-base: 'GothamBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family: 'GothamBook', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-size: 0.875rem !default;
$text-color: #495057;
$text-color-secondary: #6c757d;
$primary-color-text: #ffffff;

// Color
$brand-blue: #009dd9;
$light-blue: #00b2d7;
$blue: #0066b2;
$dark-blue: #0b2d71;
$light-gray: #f4f4f4;
$dark-gray: #acacac;
$white: #fff;
$black: #000;

$primary: #0b2d71 !default;
$secondary: #607d8b !default;
$success: #28a745 !default;
$info: #17a2b8 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #ffff !default;
$dark: #000 !default;

$theme-colors: () !default;
$theme-colors: map.merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark
  ),
  $theme-colors
);

// Surfaces

$body-bg: #f4f4f4;
$content-bg: #ffffff;
$card-bg: #ffffff;
$surface-c: #e9ecef;
$surface-d: #dee2e6; // filled input bg/border-color
$surface-f: #ffffff; // unknown
