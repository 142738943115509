/* You can add global styles to this file, and also import other style files */

// App-defined variables & typography
@import 'variables';
@import 'typography';
// 1. Configuration and helpers
@import 'bootstrap/scss/bootstrap-reboot';
@import 'reset';

// 2. Vendors
@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';

@import '~@angular/cdk/overlay-prebuilt.css';

// 3. Base stuff
// 4. Layout-related sections
// 5. Components
// 6. Page-specific styles

// 7. Themes
// TODO: Refactor PrimeNg Saga-Blue Theme
@import 'saga-blue';

@import 'overrides';

.dialog {
  width: 80%;

  .p-dialog-title {
    color: $primary;
  }
}

.tooltip {
  max-width: max-content !important;
  .p-tooltip-text {
    white-space: pre !important;
  }
}

.module {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 10px 20px 10px;
}

.box-shadow {
  -webkit-box-shadow: 2px 2px 0 0 #dbdcdd;
  -moz-box-shadow: 2px 2px 0 0 #dbdcdd;
  box-shadow: 2px 2px 0 0 #dbdcdd;
}

/*#region Width*/
.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w5 {
  width: 5%;
}
/*#endregion*/

.fr {
  float: right;
}
.fl {
  float: left;
}
.div-center-100 {
  display: flex;
  align-items: center;
  height: 100%;
}
.tac {
  text-align: center;
}
.tae {
  text-align: end;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.p-element.p-confirm-dialog-reject,
.p-element.p-confirm-dialog-accept {
  margin-bottom: 0.25rem !important;
}

.p-button.p-button-bold > span.p-button-icon {
  font-weight: 800 !important;
}

.p-button.p-button-s130 > span.p-button-icon {
  transform: scale(1.3);
}
