@use "variables";

@import '../assets/fonts/GothamBold/font.css';
@import '../assets/fonts/Gotham/HCo_fonts.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: variables.$primary;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
}
