@font-face {
	font-family: 'gothambold1';
	src: url('./gothambold1.eot');
	src: local('gothambold1'), url('./gothambold1.woff') format('woff'), url('./gothambold1.ttf') format('truetype');
}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_5903">Text with this font applied</p> */
.fontsforweb_fontid_5903 {
	font-family: 'gothambold1' !important;
}

/* Font downloaded from FontsForWeb.com */