// PrimeNg Table

.p-datatable {
  border: 2px solid #e9ecef;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: white !important;
}

.p-steps-item .p-menuitem-link {
  cursor: pointer;
}

.p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
    custom-progress-spinner-color 6s ease-in-out infinite !important;
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes custom-progress-spinner-color {
  from {
    stroke: #0b2d71;
  }
  to {
    stroke: #0b2d71;
  }
}

.sidebar .p-menu {
  width: 100%;
  border: none;
}

.p-inputtext.ng-touched.ng-invalid {
  border-color: #f44336;
}

p-inputmask,
p-inputnumber,
p-listbox,
p-inputswitch,
p-multiselect,
p-dropdown,
p-autocomplete,
p-calendar,
p-checkbox,
p-chips,
p-radiobutton,
p-selectbutton,
p-togglebutton {
  &.ng-touched.ng-invalid > {
    .p-togglebutton.p-button,
    .p-selectbutton,
    .p-radiobutton,
    .p-chips,
    .p-checkbox,
    .p-calendar,
    .p-autocomplete,
    .p-inputtext,
    .p-listbox,
    .p-inputswitch,
    .p-multiselect,
    .p-dropdown {
      border-color: #f44336;

      & > {
        .p-inputtext,
        .p-checkbox-box,
        .p-radiobutton-box,
        .p-button {
          border-color: #f44336;
        }
      }
    }
  }
}

.navbar {
  display: contents !important;
}
